.formpage {
  padding: 20px;
  margin: 20px;

  width: 60%;
  margin-left: 23%;
  margin-top: 3%;
}

.maptop {
  margin-top: 30px !important;
  width: 100%;
  height: 400px !important;
}
ul {
  list-style-type: revert;
}
.contact {
  margin-bottom: 40px;
  line-height: 34px;
  margin-left: -26px;
}

/* whatsapp logo style  */

.btn-whatsapp-pulse {
  background: #25d366;
  color: white;
  position: fixed;
  bottom: 76px;
  right: 10px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 999;
}

@media screen and (max-width: 780px) {
  .maptop {
    height: 150px;
  }
  .mapbox {
    margin-top: 40px !important;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse-border {
  bottom: 120px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
